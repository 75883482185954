<template>
  <div class="container">
    <v-dialog v-model="openModal" persistent width="500" class="pa-sm-2">
      <v-card>
        <v-card-title>
          <v-card-title-text>Código temporal enviado</v-card-title-text>
          <v-spacer></v-spacer>
          <v-btn :disabled="verifying" depressed @click="cerrarModal">
            <span class="material-symbols-outlined">
              close
            </span>
          </v-btn>
        </v-card-title>

        <v-card-text class="font-weight-medium">
          <p>
            Hemos enviado un código temporal al correo
            <span class="font-weight-bold">{{ correoFormateado }}</span> y al
            teléfono
            <span class="font-weight-bold">{{ telefonoFormateado }}</span
            >, por favor ingrese el código de verificación.
          </p>

          <v-text-field
            v-model="otp"
            color="indigo darken-4"
            label="Ingrese el código de verificación"
            type="text"
            class="w-100"
            :disable="verifying"
            :loading="verifying"
          />

          <v-card-text
            class="py-0 blue lighten-5 pa-sm-2 rounded-lg"
            v-if="!esperando"
          >
            <v-flex class="d-flex align-center justify-space-between">
              <span class="material-symbols-outlined" style="font-size: 35px;">
                autorenew
              </span>
              <span class="text-description"
                >¿No llegó el código temporal? Para reenviarlo haga
                <a
                  class="black--text text-decoration-underline"
                  @click="sendOTP"
                  >clic aquí </a
                >.
              </span>
            </v-flex>
          </v-card-text>

          <v-card-text class="text-center py-0" v-else>
            <p class="text-description">
              Si no recibe su código de verificación, puede volver a intentarlo
              dentro de {{ otpTimer }} segundos.
            </p>
          </v-card-text>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            block
            color="blue"
            elevation="2"
            raised
            rounded
            x-large
            @click="confirmOTP"
          >
            Ingresar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="4000" top>
      {{ text }}
    </v-snackbar>
    <v-snackbar
      v-model="errorEvent"
      color="error"
      top
      multi-line
      timeout="4000"
    >
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import utilsSerive from "@/services/utils.service.js";
import otpService from "@/services/otp.service.js";

export default {
  name: "ModalTarjetaCredito",
  data() {
    return {
      esperando: false,
      otp: "",
      verifying: false,
      snackbar: false,
      snackbarColor: "default",
      text: "",
      errorEvent: false,
      errorMessage: "",
      otpTimer: 20,
      timerId: null
    };
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      generalInfo: (state) => state.users.generalInformation,
      tarjeta: (state) => state.tdr.tarjeta,
      preferredLocation: state => state.products.preferredLocation,
    }),
    ...mapGetters({
      creditCard: "products/activeCreditCard",
      acceptedCreditCard: "products/acceptedOrDelivered",
    }),
    celular() {
      if(this.generalInfo.celular) {
        return this.generalInfo.celular
      }
      return this.preferredLocation.mobile
    },
    email() {
      if(this.generalInfo.email) {
        return this.generalInfo.email
      }
      return this.preferredLocation.email
    },
    telefonoFormateado() {
      return utilsSerive.formatToHiddenPhone(this.celular);
    },
    correoFormateado() {
      return utilsSerive.formatToHiddenEmail(this.email);
    },
    celularIsNotNull() {
      return this.celular !== null && this.celular !== "";
    },
    emailIsNotNull() {
      return this.email !== null && this.email !== "";
    },
    hasActiveCreditCard() {
      return this.creditCard !== null;
    },
    external_id() {
      return this.acceptedCreditCard.external_id;
    },
    contratoFormateado() {
      return utilsSerive.addLeadingZeros(this.tarjeta.numeroContrato, 12);
    },
    centroDeAltaFormateado() {
      return utilsSerive.addLeadingZeros(this.tarjeta.centroDeAlta, 4);
    },
    phoneWithCountryCode() {
      return utilsSerive.addCountryCode(this.generalInfo.celular)
    }
  },
  methods: {
    ...mapMutations({
      setOpenModal: "ui/setIsOpenModalOtp",
      setNumberTD: "users/setNumber_td",
      TD_Autenticated: "users/setTD_Autenticaded",
      setFechaExpiracion: "users/setFechaExpiracion",
      setErrorValidationTC: "users/setErrorValidationTC",
    }),
    abrirModal() {
      this.sendOTP();
    },
    cerrarModal() {
      this.setOpenModal(false);
    },
    async sendOTP() {
      console.log(this.external_id);
      if (this.emailIsNotNull && this.celularIsNotNull) {
        if (this.acceptedCreditCard) {
          this.TD_Autenticated(false);
          this.esperando = true;
          //console.log(this.creditCard)

          const data = {
            Id: this.external_id, // Recuperar la data del external_id
            celular: utilsSerive.removeCountryCode(this.celular),
            email: this.email,
          };
          console.log(data);

          const res = await otpService.generateOTP(data);

          const isSuccessfull = res.status === 200 ? true : false;
          if (isSuccessfull) {
            otpService.saveIdentifier(res.data.identificador);

            if (this.esperando) {
              this.startOtpTimer()
              setTimeout(() => {
                // Después de 20 segundos, si el usuario aún no ha recibido la confirmación, cambia resendOTP a false
                this.esperando = false; // Después de 20 segundos, se puede volver a enviar el OTP
              }, 20000);
              return
            }
          } else {
            this.errorEvent = true;
            this.errorMessage = "Hubo un probelma enviando el OTP";
          }
        } else {
          this.errorEvent = true;
          this.errorMessage =
            "No tiene productos activos de tarjeta de credito";
        }
      } else {
        this.errorEvent = true;
        this.errorMessage = "Debe tener completos los datos de celular y email";
      }
      setTimeout(() => {
          this.setOpenModal(false);
        }, 4000);
    },
    async confirmOTP() {
      this.verifying = true;
      const res = otpService.getIdentifier();
      if (res.exist) {
        const data = {
          Id: this.external_id,
          identificador: res.msg,
          textoOTP: this.otp,
          centroDeAlta: this.centroDeAltaFormateado,
          contrato: this.contratoFormateado,
          numTelefono: this.phoneWithCountryCode,
        };
        let resValidation;
        try {
          resValidation = await otpService.validateOTP(data);
        } catch (error) {
          resValidation = error.response;
        }
        console.log(resValidation.status);
        console.log(resValidation.data);
        const isSuccessfull = resValidation.status === 200 ? true : false;
        let number = "";
        let fechaExpiracion = "";
        if (isSuccessfull) {
          this.verifying = false;
          this.snackbarColor = "success";
          this.text = `OTP procesado con éxito`;
          this.snackbar = true;
          number = resValidation.data.mensaje.tcNumber;
          fechaExpiracion = resValidation.data.mensaje.fechaExpiracion;
          this.TD_Autenticated(true);
          this.setErrorValidationTC(false);
        } else {
          if (resValidation.data.status === '100'){
            // noy hay datos disponibles en finandina
            this.setErrorValidationTC(true);
          }
          else {
            // error validacion OTP o error en el servicio de finandina
            let error = resValidation.data.mensaje
            if(!error.includes("OTP"))
              error = 'Ha ocurrido un error trantando de obtener la informacion de su tarjeta'
            this.snackbarColor = "error";
            this.text = error;
            this.snackbar = true;
          }
        }
        this.verifying = false;
        // espera nescesaria para que se muestre el snackbar antes de cerrar el componente
        setTimeout(() => {
          this.setNumberTD(number);
          this.setFechaExpiracion(fechaExpiracion);
          this.setOpenModal(false);
        }, 3000);
      } else {
        throw new Error("No tiene productos activos de tarjeta de credito");
      }
    },
    startOtpTimer() {
      this.timerId = setInterval(() => {
        if (this.otpTimer > 0) {
          this.otpTimer--;
        } else {
          this.stopTimer();
        }
      },1000)
    },
    stopTimer() {
      if (this.timerId) {
        clearInterval(this.timerId);
        this.timerId = null;
        this.otpTimer = 20
      }
    }
  },
  async created() {
    if(!this.preferredLocation.mobile) {
      await this.$store.dispatch("products/getPreferredLocation")
    }
  },
  mounted() {
    this.sendOTP();
  },
  beforeDestroy() {
    this.stopTimer()
  }
};
</script>

<style scoped>
.click-zone {
  cursor: pointer;
  text-decoration: none;
}
.text-description {
  font-weight: 500;
}
</style>
